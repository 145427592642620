import { intializeHeadroom } from "../../../bladejs/initialize-headroom";
import { toggleAdvertiserDisclosure } from "../../../bladejs/toggle-advertiser-disclosure";

document.addEventListener("DOMContentLoaded", () => {
	function backToTop() {
		document.getElementById("backToTop").addEventListener("click", () => {
			window.scrollTo({ top: 0, behavior: "smooth" });
		});
	}

	backToTop();

	// Used sitewide for hiding/displaying header on scroll
	intializeHeadroom();

	// Controls the display of the advertiser disclosure
	toggleAdvertiserDisclosure();
});
